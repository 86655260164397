<template>
  <div>
    <v-card class="pa-0" outlined>
      <HeaderTableTabs :currentTab.sync="currentTab" :tabs="tabs" />
      <Resource
        :resourceType="resourceType"
        :document="displayProducts"
        :countt="parseInt(countt)"
        :filter-fields="filter"
        :filter-data="filterData"
        :filter-callback="setFilterData"
        :searchable="searchableField"
        :columns="columns"
        :update-router-callback="getUpdateRouterData"
        :callback-action="getAction"
        :callback-selection="getIds"
        :actionItems="items"
        :routeName="updateRouteName"
        @closeClicked="closeClicked"
        @getType="getType"
        class="px-3 mb-4"
      />
      <div class="d-flex justify-space-between px-3">
        <limit-page :callback="setLimit" :page.sync="page" />
        <pagination :length="1000000000000" :visible="0" :page.sync="page"></pagination>
      </div>
    </v-card>
    <v-dialog persistent v-model="showModalAddTagsProduct" width="700" style="transform-origin: center top">
      <v-card relative>
        <!-- Title -->
        <v-card-title class="headline"
          >{{ action === 'add-tags' ? ' Add tags' : 'Remove tags' }}
          {{ this.selectAll === 'all' ? productQuantity : ids.length }} products.
        </v-card-title>
        <!-- Close icon -->
        <div class="close-icon" @click="closeShowModalAddTagsProduct">
          <v-icon>fas fa-times</v-icon>
        </div>
        <v-divider class="mb-3"></v-divider>
        <v-row no-gutters d-flex justify="center">
          <v-col cols="11">
            <h3 class="fz-15">Tags</h3>
            <v-combobox
              v-model.trim="tags"
              hide-selected
              multiple
              persistent-hint
              :delimiters="delimiters"
              small-chips
              placeholder="Enter tags"
              hide-details
              append-icon=""
              @change="onChangeTag"
            >
              <template v-slot:selection="data">
                <v-chip
                  close
                  small
                  label
                  :key="data.item"
                  :disabled="data.disabled"
                  @click:close="data.parent.selectItem(data.item)"
                >
                  {{ data.item }}
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-card-subtitle class="pl-8 pt-3 pb-2">
          Select existing tags:
        </v-card-subtitle>
        <v-chip-group column class="group-tags">
          <v-chip small v-for="(item, ind) in listTags" :key="ind" @click="pushTags(item)">
            {{ item }}
          </v-chip>
        </v-chip-group>
        <!-- <div v-for="">
          <v-chip>111</v-chip>
        </div>
         -->
        <v-divider class="my-3"></v-divider>
        <!-- Footer -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" @click="closeShowModalAddTagsProduct"> Close </v-btn>
          <v-btn :loading="isLoadingButton" color="primary" @click="onUpdateBulk"
            >{{ action === 'add-tags' ? ' Add tags' : 'Remove tags' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="showModalSelectCollection" width="700" style="transform-origin: center top">
      <v-card relative>
        <!-- Title -->
        <v-card-title class="headline">
          {{ action === 'add-collection' ? 'Add collection' : 'Remove collection' }} for
          {{ this.selectAll === 'all' ? productQuantity : ids.length }} products.
        </v-card-title>
        <v-row no-gutters d-flex justify="center">
          <v-col cols="11">
            <v-text-field autofocus class="px-4" v-model="keySearchCollection"></v-text-field>
          </v-col>
        </v-row>

        <!-- Close icon -->
        <div class="close-icon" @click="closeShowModalSelectCollection">
          <v-icon>fas fa-times</v-icon>
        </div>
        <v-divider class="mb-3"></v-divider>
        <div class="list-product" v-if="!isLoadingCollection">
          <!-- <div class="py-3 my-2">
            <v-divider></v-divider>
          </div> -->
          <div v-for="(i, k) in collectionSelectList" :key="i._id" class="px-4 px-12">
            <div class="d-flex align-center">
              <v-checkbox v-model="idsCollection" :value="i._id" class="mr-4"></v-checkbox>
              <v-avatar tile size="40">
                <img
                  :src="i.images && i.image.src ? imageHelpers.url(i.image.src) : '@/assets/images/no-image.png'"
                  alt=""
                />
              </v-avatar>
              <span class="ml-3 link-add">{{ i.title }}</span>
            </div>

            <v-divider v-if="k + 1 < collectionSelectList.length" class="mt-2"></v-divider>
          </div>
          <div v-if="!isLoadingCollection && collectionSelectList.length === 0" class="d-flex flex-column align-center">
            <!-- <img  :src="imageFake.Emty" alt="" srcset="" /> -->
            <empty />
            <!-- <div>No Collection</div> -->
          </div>
        </div>
        <div style="width: 100%" v-else class="list-product d-flex justify-center align-center my-3">
          <!-- <page-loader /> -->
        </div>

        <v-divider class="mb-3"></v-divider>
        <!-- Footer -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" @click="closeShowModalSelectCollection"> Close </v-btn>
          <v-btn :loading="isLoadingButton" color="primary" @click="onUpdateBulk"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="showModalDelete" width="500" style="transform-origin: center top">
      <v-card relative>
        <!-- Title -->
        <v-card-title class="headline">
          {{ 'Delete' }} {{ this.selectAll === 'all' ? productQuantity : ids.length }} products?.
        </v-card-title>
        <div class="close-icon" @click="showModalDelete = false">
          <v-icon>fas fa-times</v-icon>
        </div>
        <v-divider class="mb-3"></v-divider>
        <v-card-text class="">
          Are you sure you want to delete {{ this.selectAll === 'all' ? productQuantity : ids.length }} products. This
          action cannot be reversed.
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" @click="showModalDelete = false"> Cancel </v-btn>
          <v-btn :loading="isLoadingButton" color="error" @click="onSubmitDelete"> Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="showModelAvailable" width="500">
      <v-card>
        <v-card-title
          >Make {{ this.selectAll === 'all' ? productQuantity : ids.length }} products
          {{ action === 'make-available' ? 'available ' : 'unavailable' }}</v-card-title
        >
        <div class="close-icon" @click="showModelAvailable = false"><v-icon>fas fa-times</v-icon></div>
        <v-card-text
          >Are you sure you want to {{ action === 'make-available' ? 'enable ' : 'disable' }}
          {{ this.selectAll === 'all' ? productQuantity : ids.length }} products?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showModelAvailable = false">Cancel</v-btn>
          <v-btn color="primary" @click="onUpdateBulk"
            >Make products {{ action === 'make-available' ? 'available ' : 'unavailable' }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Resource from '@/components/Resources';
import filter from '@/const/filters/product-filter';
import resourceListMixin from '@/mixins/resource-list';
import { productApi } from '@/apis/product';
import utilities from '@/helpers/utilities';
import { collectionApi } from '@/apis/collection';
import { imageFake } from '@/const/image';
import { log } from 'handlebars';
import Empty from '../../../../components/Empty.vue';
import { FETCH_STORES } from '@/store/actions.type';
import { mapGetters } from 'vuex';
export default {
  components: { Resource, Empty },
  mixins: [resourceListMixin],
  data() {
    return {
      productQuantity: 0,
      selectAll: '',
      idSelected: '',
      listTags: '',
      delimiters: [','],
      items: [
        { title: 'Make products available', action: 'make-available' },
        { title: 'Make products unavailable', action: 'make-unavailable' },
        { title: 'Edit Product', action: 'bulk-edit' },
        //{ title: 'Delete selected products', action: 'remove-product' },
        { title: 'Add tags', action: 'add-tags' },
        { title: 'Remove tags', action: 'remove-tags' },
        { title: 'Add Collection', action: 'add-collection' },
        { title: 'Remove Collection', action: 'remove-collection' },
      ],
      imageFake,
      showModalDelete: false,
      collectionSelectList: [],
      showModalSelectCollection: false,
      idsCollection: [],
      keySearchCollection: '',
      isLoadingButton: false,
      tags: [],
      showModalAddTagsProduct: false,
      showModelAvailable: false,
      ids: [],
      available: '',
      resourceType: 'products',
      length: 1,
      action: '',
      count: 1,
      tabs: [
        { name: 'All', key: '' },
        { name: 'Available products', key: 'published' },
        { name: 'Unavailable products', key: 'unpublished' },
      ],
      testVuex: null,
      columns: {
        Selectable: {
          blankLabel: true,
          kind: 'selectable',
          width: 1,
        },
        Title: {
          // blankLabel: true,
          kind: 'viewProduct',
          field: 'title',
        },
        BaseCost: {
          kind: 'price',
          field: 'baseCost',
          style: {
            textAlign: 'end',
          },
        },
        'Selling Price': {
          kind: 'price',
          field: 'sellingPrice',
          style: {
            textAlign: 'end',
          },
        },
        '% Cost': {
          kind: 'percent',
          field: 'costPercent',
          style: {
            textAlign: 'end',
          },
        },
        Profit: {
          kind: 'text',
          field: 'profit',
          style: {
            textAlign: 'end',
          },
        },
        // 'Product Type': {
        //   field: 'productType',
        //   kind: 'title',
        // },
        // Vendor: {
        //   field: 'vendor',
        //   kind: 'title',
        // },
        // Actions: {
        //   kind: 'actions',
        //    blankLabel: true,
        // },
      },
      searchableField: 'with title',
      sortable: [],
      filter,
      updateRouteName: 'website.products.update',
      isLoadingCollection: false,
      queryDetail: '',
    };
  },
  watch: {
    selectAll() {
      if (this.selectAll === 'all') {
        this.getAllTag();
      } else {
        this.getTags();
      }
    },
    idSelected() {
      this.getTags();
    },
    keySearchCollection() {
      if (this.executor) {
        clearTimeout(this.executor);
        this.executor = null;
      }
      this.executor = setTimeout(async () => {
        await this.getCollection();
      }, 300);
    },
  },
  computed: {
    ...mapGetters(['stores']),
    storeURL() {
      return 'https://' + this.$store.state.auth.accountInfo.rDomain;
    },
    rankDiscount() {
      return this.$store.state.subscription?.currentSubscription?.rankDiscounts?.[1] || 0;
    },
    displayProducts() {
      return this.document?.map(item => ({
        ...item,
        baseCost: item.baseCost - this.rankDiscount,
      }));
    },
  },
  methods: {
    // lấy tag của tất cả product khi chọn select all
    async getAllTag() {
      let params = {
        // limit: 50,
      };
      let res = await productApi.getProductTags(params);
      // this.listTags = res.data.tags;
      this.listTags = [];
    },
    getType(data) {
      this.selectAll = data;
    },
    // backToPage1() {
    //   this.page = 1;
    // },
    pushTags(i) {
      if (this.tags.findIndex(a => a == i) == -1) {
        this.tags.push(i);
        console.log(this.tags);
      }
    },
    onChangeTag(e) {
      this.tags = e.filter(item => item.trim() !== '');
    },
    closeClicked(data) {
      this.filter.forEach(item => {
        if (item.field == data) {
          item.model = '';
        }
      });
    },
    async getTags() {
      if (this.selectAll != 'all') {
        try {
          let params = `?ids=${this.idSelected.join(',')}`;
          let res = await productApi.getTags(params);
          // this.listTags = res.data.tags;
          this.listTags = [];
        } catch (error) {}
      }
    },
    async getCollection() {
      try {
        let params = {
          page: 1,
          limit: 100,
          search: this.keySearchCollection,
        };
        this.isLoadingCollection = true;
        let res = await collectionApi.get(params);
        this.collectionSelectList = res.data.collections.filter(item => {
          return item.collectionType === 'normal';
        });
        this.$forceUpdate();
        this.isLoadingCollection = false;
      } catch (error) {}
    },
    getIds(ids) {
      this.idSelected = ids;
      this.$parent.ids = ids;
      // this.getTags();
      // console.log(this.idSelected);
    },
    async onSubmitDelete() {
      try {
        this.isLoadingButton = true;
        let query = this.$route.query;
        console.log(query);
        let convertData = {
          data: {},
          ids: this.ids,
          type: this.selectAll,
        };
        convertData.query = query;
        let tab;
        if (this.$route.query.tab == 'published') {
          tab = 'false';
        }
        if (this.$route.query.tab == 'unpublished') {
          tab = 'true';
        }
        if (this.selectAll !== 'all') {
          await productApi.remove(`?ids=${this.ids.join(',')}`);
        }
        if (this.selectAll === 'all' && query._query) {
          convertData.query = query;
        }
        convertData.data.deleted = true;
        await productApi.updateBulk(convertData, this.searchString, tab, this.selectAll);
        this.isLoading = true;
        this.showModalDelete = false;

        await this.getResources();
        this.isLoading = false;
      } catch (error) {
        //empty
      }
      this.showModalDelete = false;
      this.isLoadingButton = false;
    },
    closeShowModalAddTagsProduct() {
      this.showModalAddTagsProduct = false;
      this.tags = [];
    },
    closeShowModalSelectCollection() {
      this.showModalSelectCollection = false;
      this.keySearchCollection = '';
      this.idsCollection = [];
    },
    async onUpdateBulk() {
      try {
        let convertData = {
          data: {},
          ids: this.ids,
          type: this.selectAll,
        };
        let tab = this.$route.query.tab;
        console.log(tab);
        this.isLoadingButton = true;
        // cho vào settimeout để hàm này chạy sau khi @input ở combobox, để this.tags != ''
        setTimeout(async () => {
          let query = this.$route.query;
          console.log(query);
          convertData.query = query;
          if (this.selectAll === 'all' && query._query) {
            console.log(convertData.query);
          }
          if (this.action === 'add-tags') {
            convertData.data.add_tags = this.tags;
          }
          if (this.action === 'remove-tags') {
            convertData.data.remove_tags = this.tags;
          }
          if (this.action === 'add-collection') {
            convertData.data.add_collections = this.idsCollection;
          }
          if (this.action === 'remove-collection') {
            convertData.data.remove_collections = this.idsCollection;
          }
          if (this.action === 'make-available') {
            convertData.data.published = true;
          }
          if (this.action === 'make-unavailable') {
            convertData.data.published = false;
          }
          console.log('convertData', convertData);
          await productApi.updateBulk(convertData, this.searchString, tab, this.selectAll);

          this.showModalAddTagsProduct = false;
          this.showModalSelectCollection = false;
          this.showModelAvailable = false;
          this.idsCollection = [];
          this.tags = [];
          await this.getResources();
        }, 0);
      } catch (error) {
        console.log('error', error);
        //empty
      }
      this.isLoadingButton = false;
    },
    getAction(action, ids) {
      this.ids = ids;
      this.action = action.action;
      if (action.action === 'bulk-edit') {
        this.$router.push({
          name: 'website.products.bulkEdit',
          query: {
            ids: ids.join(','),
          },
        });
      }
      if (action.action === 'add-tags' || action.action === 'remove-tags') {
        this.showModalAddTagsProduct = true;
        this.tags = [];
      }
      if (action.action === 'add-collection' || action.action === 'remove-collection') {
        this.showModalSelectCollection = true;
      }
      if (action.action === 'make-available' || action.action === 'make-unavailable') {
        this.showModelAvailable = true;
      }
      if (action.action === 'remove-product') {
        this.showModalDelete = true;
      }
    },
  },
  async created() {
    await this.getCollection();
    // await this.getTags();
    await this.$store.dispatch(FETCH_STORES);
  },
};
</script>
